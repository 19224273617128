import logoLink from '../resources/command.svg';

export const cssVariables = {
    primary_light: 'rgb(153, 203, 195)',
    primary: 'rgb(0, 168, 165)',
    text_on_primary: 'white',
    primary_dark: 'rgb(0, 131, 117)',

    secondary: '#D25425',
    secondary_light: '#FBEEE9',
    text_on_secondary: 'white',
    secondary_transparent: '#FFFBFA',

    link_color: 'rgb(0, 131, 117)',
    font_color: '#000',

    font: '"TT Rounds Condensed", sans-serif',
 
    border_width: '1px',
    border_style: 'solid',

    active: 'rgb(183, 233, 225)',
    inactive: '#ECB393',

    centerChatBlock__delete:'#D25425',
};

export const logo = logoLink;
